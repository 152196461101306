<!--
 * @Description: 业务相关审批流程模板
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:55:24
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-19 11:49:32
-->
<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus">新增</el-button>
  </el-card>
  <el-card class="box">
    <el-collapse v-model="activeNames" v-if="data_list.length">
      <el-collapse-item :name="i" v-for="(item,i) in data_list" :key="item.type_id">
        <template #title>
          <b>{{item.flow_code_text}}({{item.templates.length}})</b>
        </template>
        <div class="template_container">
          <el-card class="template_item" shadow="never" v-for="x in item.templates" :key="x.id">
            <div class="name">{{x.name}}</div>
            <div>
              状态：
              <el-link type="danger" :underline="false" v-if="x.is_stop">停用</el-link>
              <el-link type="success" :underline="false" v-else>正常</el-link>
            </div>
            <div class="bottom" v-if="x.business_id">
              <el-button type="primary" link @click="onEdit(x)">修改</el-button>
            </div>
          </el-card>
        </div>
      </el-collapse-item>
    </el-collapse>
    <edit :model="current" @success="onEditSuccess"></edit>
  </el-card>
 
</template>
<script>
import Edit from "./edit.vue";
export default {
  components: {
    Edit,
  },
  data() {
    return {
      loading: false,
      saving: false,
      //数据
      data_list: [],
      //当前
      current: null,
      //
      activeNames: 0,
      //编辑类型弹框
      visibleDialog: false,
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("seller/v1/flowTemplate/bus").then((res) => {
        if (res.code == 0) {
          this.data_list = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = Object.assign({}, item);
    },
    /**
     *
     */
    onEditSuccess() {
      this.current = null;
      this.loadData();
    },

    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("/seller/v1/flowTemplate/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
  },
};
</script>

<style scoped>
.template_container {
  display: flex;
  flex-flow: wrap;
}
.template_container .template_item {
  width: 240px;
  position: relative;
  margin-right: 20px;
  margin-top: 20px;
}
.template_container .template_item .name {
  font-weight: 600;
  font-size: 16px;
}

.template_container .template_item .tag {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  /* padding: 0 10px; */
  width: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: var(--el-card-border-radius);
}
.template_container .template_item .custom {
  background: #337ecc;
}
.template_container .template_item .general {
  background: #b1b3b8;
}

.template_container .template_item .bottom {
  display: flex;
  justify-content: flex-end;
}
</style>
